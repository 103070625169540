import * as React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './App.css';
import { brand$ } from './surveyCollector/screenStreams';
import Layout from './uiState/Layout';
import { state$ } from './uiState/states';
import { useObservableState } from 'observable-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createMuiTheme,
  MuiThemeProvider,
  createGenerateClassName,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import JssProvider from 'react-jss/lib/JssProvider';
import {
  audiTheme,
  defaultTheme,
  skodaTheme,
  seatTheme,
  vwTheme,
  porsheTheme,
  passengerVWTheme,
  produktCXTheme,
  cupraTheme,
} from './theme';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18next';
import { propOr } from 'ramda';
import { ThemeOptions } from '@material-ui/core/es/styles/createMuiTheme';
import { BrandsEnum } from './surveyCollector/SurveyCollector';
import TagManager from 'react-gtm-module';
import {Helmet} from "react-helmet";
import { getFirstBrowserLanguage } from './surveyCollector/languageDetection';

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

const themeByBrand = {
  [BrandsEnum.SEAT]: seatTheme,
  [BrandsEnum.AUDI]: audiTheme,
  [BrandsEnum.SKODA]: skodaTheme,
  [BrandsEnum.VW]: vwTheme,
  [BrandsEnum.NEVQS]: produktCXTheme,
  [BrandsEnum.PORSCHE]: porsheTheme,
  [BrandsEnum.PASSENGERVW]: passengerVWTheme,
  [BrandsEnum.CUPRA]: cupraTheme,
};

const generateClassName = createGenerateClassName();

const App = () => {
  const state = useObservableState(state$);
  const brand = useObservableState(brand$);

  if (!state || !brand) {
    return null;
  }

  const currentTheme = propOr(defaultTheme, brand.code, themeByBrand);

  const theme = createMuiTheme(currentTheme as ThemeOptions);

  const tagManagerArgs = {
    gtmId: 'GTM-M9SSKNF'
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <Helmet>
            <html lang={((getFirstBrowserLanguage().slice(0,2)) == "cs" ? "cs" : "en") + "-cz"}/>
            <meta name="legal-entity-id" content="260"/>
              <script src="https://cross.skoda-auto.com/sdrive/endpoint.js" type="text/javascript" />
          </Helmet>
          <Layout state={state} />
        </I18nextProvider>
      </MuiThemeProvider>
    </JssProvider>
  );
};

export default App;
