import {
  CustomTheme,
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import * as React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import classNames from 'class-names';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { BrandsEnum } from '../../surveyCollector/SurveyCollector';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.brandCode === BrandsEnum.SKODA ? '51px' : '30px',
    },
  },
  rootWithImage: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.brandCode === BrandsEnum.SKODA ? '0px' : '30px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      paddingTop: theme.brandCode === BrandsEnum.SKODA ? '40px' : '30px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.brandCode === BrandsEnum.SKODA ? '0px' : '30px',
    },
  },
  lightLineBar: {
    background:
      theme.brandCode === BrandsEnum.SKODA
        ? '#ffffff'
        : theme.palette.primary.light,
  },
  lineBar: {
    background: theme.palette.primary.main,
    transition: 'width 400ms ease-in-out 50ms',
    [theme.breakpoints.up('sm')]: {
      height: 4,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
    position: 'initial',
    zIndex: theme.brandCode && 55,
  },
  imgBar: {
    minWidth: 73,
    transition: 'width 400ms ease-in-out 50ms',
  },
  img: {
    maxWidth: theme.brandCode === BrandsEnum.SKODA ? '84px' : '73px',
    width: 'auto',
    height: theme.brandCode === BrandsEnum.SKODA ? '42px' : '30px',
    float: 'right',
    marginTop: theme.brandCode === BrandsEnum.SKODA ? '-42px' : '-30px',
    transform: `scaleX(${theme.direction === 'rtl' ? -1 : 1})`,
  },
  percentBar: {
    visibility: theme.brandCode === BrandsEnum.PORSCHE ? 'hidden' : 'visible',
    position: 'relative',
    background:
      theme.brandCode === BrandsEnum.SKODA
        ? '#ffffff'
        : theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 50,
    height: 20,
    float: 'right',
    margin: '11px -25px 0 0',
    '&:before': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: '0',
      width: '0',
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(194, 225, 245, 0)',
      borderBottomColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#ffffff'
          : theme.palette.primary.main,
      borderWidth: '8px',
      marginLeft: '-8px',
    },
    zIndex: 50,
  },
  valuePercent: {
    marginRight: theme.spacing(),
    color: '#ffffff',
    fontSize: 14,
  },
}));

type ProgressProps = {
  totalCount: number;
  completed: number;
  image?: string;
  showValue?: boolean;
};
type BreakpointOrNull = Breakpoint | null;

function calculationOfCompletion(totalCount: number, completed: number) {
  return Math.ceil((completed / totalCount) * 100);
}

const useWidth = () => {
  const theme: CustomTheme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

// if it is problem with car on pc https://gitlab.commity.cz/fieldwork/survey-collector/merge_requests/97#note_24296

const SurveyProgress: React.FunctionComponent<ProgressProps> = ({
  totalCount,
  completed,
  image,
  showValue = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();
  const value = calculationOfCompletion(totalCount, completed);
  let minValue = value;
  const isWidthXs = width === 'xs';
  const isXsSkoda =
    (width === 'xs' || width === 'sm') &&
    theme.brandCode === BrandsEnum.SKODA &&
    value < 6;
  minValue = isXsSkoda ? 6 : value;
  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      <div
        className={classNames(classes.root, {
          [classes.rootWithImage]: image,
        })}>
        <div className={classes.lightLineBar}>
          {!isWidthXs && (
            <div className={classes.imgBar} style={{ width: `${value}%` }}>
              {image ? (
                <img
                  className={classes.img}
                  data-test-id="progressImage"
                  src={image}
                />
              ) : (
                ''
              )}
            </div>
          )}
          <div className={classes.lineBar} style={{ width: `${minValue}%` }}>
            {isWidthXs ? (
              <span className={classes.valuePercent}>{value}%</span>
            ) : (
              <span>
                {value && showValue ? (
                  <div className={classes.percentBar}>
                    <Typography
                      color="inherit"
                      align="center"
                      variant="body1"
                      style={{ fontSize: '14px' }}>
                      {value}%
                    </Typography>
                  </div>
                ) : (
                  ''
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default SurveyProgress;
