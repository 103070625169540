import i18next from 'i18next';
import * as R from 'ramda';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  fromEvent,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  observeOn,
  pairwise,
  pluck,
  scan,
  share,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import flags from '../flags';
import {
  displayConnectionError,
  getLastTransition,
  StateType,
} from '../uiState/states';
import { processPostHooks } from './elementHooks';
import {
  getElementIds,
  questionIdsToEvents,
  screenControlledByUser,
} from './questionEvents';
import { screenActionsFactory } from './screenActions';
import { getStartPath, pathComparator } from './screenPath';
import {
  applyElementsPreHooks,
  canGoNext,
  canUseAutoNext,
  changeScreenValues,
  createScreenDefinition,
  extendElementsFactory,
  filterInvisibleElements,
  getCurrentScreenElements,
  getSurveyElement,
  moveRequestToPath,
  preserveProcessCollectedDataFlag,
  setNextRequested,
  startCollectedDataProcessing,
  stopCollectedDataProcessing,
  updateStateInfo,
  visibleStateToScreenState,
} from './screenProcessing';
import {
  Brand,
  Config,
  MoveSource,
  QuestionEvent,
  Questionnaire,
  ScreenElement,
  ScreenMove,
  SurveyData,
  SurveyMetadata,
  SurveyParams,
  SurveyScreen,
  SurveyScreenDefinition,
  Values,
} from './SurveyCollector';
import { getSurveyProgress } from './surveyProgress';
import { sendData } from './surveySubmitting';
import {
  createScreenValuesChange,
  filterDataToSend,
  filterMoveRequestToSave,
  mergeAndCleanEmptyData,
  moveToDataCollectionType,
  prepareDataToSend,
  sendDataComparator,
  toCollectedData,
  toTemporaryData,
} from './valuesProcessing';
import { mergeAll } from 'ramda';

// *** data ******

const surveyParams$ = new Subject<SurveyParams>();
const sampleData$ = new Subject<Values>();

export const brand$ = new BehaviorSubject<Brand>({ code: '' });

const collectedDataInitial$ = new Subject();
const collectedDataChange$ = new Subject();

const collectedData$ = merge(collectedDataInitial$, collectedDataChange$).pipe(
  scan(mergeAndCleanEmptyData, {}),
  startWith({})
);

const temporaryDataInitial$ = new Subject();
const temporaryDataChange$ = new Subject();

const temporaryData$ = merge(temporaryDataInitial$, temporaryDataChange$).pipe(
  scan(mergeAndCleanEmptyData, {})
);

const eventsInitial$ = new Subject<QuestionEvent[]>();
const eventsToAdd$ = new Subject<QuestionEvent[]>();

const events$ = merge(eventsInitial$, eventsToAdd$).pipe(
  // BE return array or null
  map(R.defaultTo([])),
  scan<QuestionEvent[]>(R.concat)
);

// tslint:disable-next-line: deprecation
const variables$: Observable<Values> = combineLatest(
  sampleData$,
  collectedData$
).pipe(
  map(([sampleData, collectedData]) => R.merge(sampleData, collectedData))
);

// tslint:disable-next-line: deprecation
const values$: Observable<Values> = combineLatest(
  temporaryData$,
  collectedData$
).pipe(
  map(([temporaryData, collectedData]) => R.merge(temporaryData, collectedData))
);

const stopCollectedDataProcessing$ = new Subject();
const onStopCollectedDataProcessing = () =>
  stopCollectedDataProcessing$.next(null);

const surveyDefinition$ = new Subject<Questionnaire>();

const surveyPath$ = new BehaviorSubject(getStartPath());

// *** events ******

const valuesChanged$ = new Subject();
const onChangeFor = (id) => (value) =>
  valuesChanged$.next({
    [id]: value,
  });

const blur$ = new Subject();
const onBlurFor = (id) => () => blur$.next(id);

const jumpRequested$ = new Subject<ScreenMove>();
const onJump = (move: ScreenMove) => jumpRequested$.next(move);
export const jumpToEnd = (source: MoveSource) =>
  onJump({ moveDirection: 'end', source });

const nextRequested$ = new Subject<ScreenMove>();
const onNext = (reason: MoveSource) =>
  nextRequested$.next({ moveDirection: 'next', source: reason });
export const onNextClick = () => onNext('surveyNavigation');

const previousRequested$ = new Subject<ScreenMove>();
const onPrevious = (reason: MoveSource) =>
  previousRequested$.next({ moveDirection: 'previous', source: reason });
export const onPreviousClick = () => onPrevious('surveyNavigation');

const autoNextRequested$ = new Subject<ScreenMove>();
export const onAutoNext = () =>
  autoNextRequested$.next({
    moveDirection: 'next',
    source: 'autoNext',
  });

const resendEvent$ = new BehaviorSubject(0);

export const resendData = () => resendEvent$.next(1);

// *** screens ******

// tslint:disable-next-line: deprecation
const questionnaireState$ = combineLatest(surveyDefinition$, surveyPath$).pipe(
  map(([questionnaire, path]) => ({
    questionnaire,
    path,
  }))
);

export const questionnaireProgress$ = questionnaireState$.pipe(
  map<any, any>(({ questionnaire, path }) =>
    getSurveyProgress(questionnaire, path, flags.progress.mode)
  )
);

const screenElement$ = questionnaireState$.pipe(
  map(({ questionnaire, path }) => getSurveyElement(path, questionnaire))
);

const screenDefinition$ = screenElement$.pipe(
  withLatestFrom(values$, variables$),
  map(
    ([screenElement, values, variables]: [ScreenElement, Values, Values]) => ({
      screenElement,
      elementDefinitions: extendElementsFactory(
        {
          onChangeFor,
          onBlurFor,
          onStart: onNextClick,
          onJump,
        },
        {
          variables,
        }
      )(getCurrentScreenElements(screenElement)),
      values,

      variables,
    })
  ),
  map(({ elementDefinitions, values, variables, screenElement }) =>
    createScreenDefinition(elementDefinitions, values, variables, screenElement)
  )
);
const screenAction$ = merge(
  screenDefinition$.pipe(
    map(
      (screen: SurveyScreenDefinition) => (oldScreen: SurveyScreenDefinition) =>
        preserveProcessCollectedDataFlag(oldScreen, screen)
    )
  ),
  valuesChanged$.pipe(
    map((newValues: unknown) => (screen: SurveyScreenDefinition) =>
      changeScreenValues(screen, newValues as Values)
    )
  ),
  nextRequested$.pipe(
    filter((move) => move.source === 'surveyNavigation'),
    mapTo(setNextRequested)
  ),
  stopCollectedDataProcessing$.pipe(mapTo(stopCollectedDataProcessing)),
  temporaryDataInitial$.pipe(
    first(),
    filter(R.complement(R.isEmpty)),
    mapTo(startCollectedDataProcessing)
  )
) as Observable<() => SurveyScreenDefinition>;

const reducer = (state, action) => action(state);

// *** state ******

const screen$: Observable<SurveyScreenDefinition> = screenAction$.pipe(
  startWith(createScreenDefinition([])),
  scan(reducer)
);

const visibleScreen$: Observable<SurveyScreen> = screen$.pipe(
  withLatestFrom(variables$, events$),
  map(applyElementsPreHooks),
  map(filterInvisibleElements),
  map(updateStateInfo),
  withLatestFrom(questionnaireProgress$),
  map(([screen, progress]) => R.assoc('progress', progress, screen)),
  //  distinctUntilChanged<SurveyScreen>(R.equals),
  share()
);

const validNextRequested$ = nextRequested$.pipe(
  withLatestFrom(visibleScreen$),
  map(([move, screen]) => (canGoNext(screen) ? move : null)),
  filter(Boolean)
);

const moveRequest$: Observable<ScreenMove> = merge(
  jumpRequested$,
  previousRequested$,
  validNextRequested$
).pipe(
  withLatestFrom(visibleScreen$, collectedData$),
  map(([move, screen, collectedData]) =>
    processPostHooks(screen, move, collectedData)
  ),
  filter(Boolean),
  observeOn(asyncScheduler),
  share()
);

const autoNext$ = autoNextRequested$.pipe(
  withLatestFrom(visibleScreen$),
  map(([move, screen]) => (canUseAutoNext(screen) ? move : null)),
  filter(Boolean)
);

const moveAsNextPath$ = moveRequest$.pipe(
  withLatestFrom(questionnaireState$),
  map(([move, { questionnaire, path }]) =>
    moveRequestToPath(questionnaire, path, move)
  ),
  observeOn(asyncScheduler),
  share()
);

const saveDataRequest$ = moveRequest$.pipe(
  filter(filterMoveRequestToSave),
  map(moveToDataCollectionType),
  withLatestFrom(visibleScreen$),
  map(([dataCollectionType, screen]) =>
    createScreenValuesChange(dataCollectionType, screen)
  ),
  share()
);

const collectedDataToSave$ = saveDataRequest$.pipe(map(toCollectedData));

const temporaryDataToSave$ = saveDataRequest$.pipe(map(toTemporaryData));

const dataToSend$ = screen$.pipe(
  withLatestFrom(collectedData$, events$),
  map(([screen, collectedData, events]) =>
    prepareDataToSend(screen, collectedData, events)
  ),
  filter(filterDataToSend),
  distinctUntilChanged(sendDataComparator),
  debounceTime(100),
  // tslint:disable-next-line: deprecation
  (data$) => combineLatest(data$, resendEvent$),
  // @ts-ignore
  map(([data, _]) => data),
  withLatestFrom(surveyParams$)
);

const questionEvents$ = visibleScreen$.pipe(
  filter(screenControlledByUser),
  map(getElementIds),
  distinctUntilChanged(R.equals),
  startWith([]),
  pairwise(),
  map(questionIdsToEvents)
);

// *** browser history

const history$ = fromEvent(window, 'popstate');
history$
  .pipe(
    pluck('state'),
    map(
      R.defaultTo({
        path: getStartPath(),
      })
    ),

    withLatestFrom(questionnaireState$.pipe(pluck('path'))),
    // @ts-ignore
    map(([{ path: currentPath, moveDirection }, requiredPath]) =>
      pathComparator(requiredPath, currentPath)
    ),
    filter(Boolean)
  )
  .subscribe((v) => {
    if (v > 0) {
      onNext('browserNavigation');
    } else {
      onPrevious('browserNavigation');
    }
  });

questionnaireState$
  .pipe(
    pluck('path'),
    distinctUntilChanged(R.equals),
    withLatestFrom(moveRequest$),
    filter(
      ([pathState, moveRequest]) =>
        moveRequest.source === 'autoNext' ||
        moveRequest.source === 'surveyNavigation'
    )
  )
  .subscribe(([pathState, moveRequest]) =>
    history.pushState(
      {
        path: pathState,
        moveDirection: moveRequest.moveDirection,
      },
      'Survey'
    )
  );

// *** initialize

export const init = (
  config: Config,
  surveyParams: SurveyParams,
  surveyDefinition: Questionnaire,
  sampleData: Values = {},
  collectedData: Values = {},
  events: QuestionEvent[] = [],
  translations = {}, // TODO unify access to translations and surveyDefinition variants
  surveyMetadata: SurveyMetadata,
  brand: Brand
) => {
  document.body.dir = surveyMetadata.directionality;

  i18next.addResourceBundle(
    surveyMetadata.surveyLanguage,
    'translation',
    translations
  );
  i18next
    .changeLanguage(surveyMetadata.surveyLanguage)
    .catch((e) => console.error('Change language error', e));

  moveAsNextPath$.subscribe(surveyPath$);
  collectedDataToSave$.subscribe(collectedDataChange$);
  temporaryDataToSave$.subscribe(temporaryDataChange$);
  questionEvents$.subscribe(eventsToAdd$);
  autoNext$.subscribe(nextRequested$);

  visibleScreen$
    .pipe(
      tap(visibleStateToScreenState(surveyMetadata)),
      withLatestFrom(moveRequest$.pipe(startWith({})))
    )
    .subscribe(
      screenActionsFactory(
        onNext,
        onPreviousClick,
        onAutoNext,
        onStopCollectedDataProcessing
      )
    );

  dataToSend$.subscribe(([data, surveyParams]) => {
    const surveyData = data as SurveyData;

    sendData(config, surveyParams, surveyMetadata, data as SurveyData)
      .then((surveyData) => {
        const lastTransition = getLastTransition();

        if (
          surveyData.surveyStatus === 'FINISHED' &&
          lastTransition.type === StateType.ConnectionErrorDisplayed
        ) {
          jumpToEnd('offlineRecovery');
        }
      })
      .catch((surveyData) => {
        if (
          surveyData.surveyStatus === 'FINISHED' &&
          !config.REACT_APP_PREVIEW_MODE
        ) {
          displayConnectionError();
        }
      });
  });

  eventsInitial$.next(events);
  surveyParams$.next(surveyParams);
  sampleData$.next(sampleData);

  // we process older collected data as temporaryData, because it can deffer from final shape of data.
  temporaryDataInitial$.next(collectedData);

  surveyDefinition$.next(surveyDefinition);

  brand$.next(brand);
};
