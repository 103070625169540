import * as React from 'react';
import { IntroProps } from './ElementTypeInterfaces';
import {
  Button,
  Checkbox,
  CustomTheme,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import Linkify from 'linkify-react';
import nl2br from 'nl2br';
// @ts-ignore
import striptags from 'striptags';
import OptOut from './OptOut';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
  },
  fullWidthGridItem: {
    width: '100%',
  },
  squareButton: {
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    boxShadow: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: theme.brandCode === BrandsEnum.SKODA ? '500px' : '400px',
      minWidth: theme.brandCode === BrandsEnum.SKODA ? '500px' : '400px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.brandCode === BrandsEnum.SKODA ? '695px' : '400px',
      minWidth: theme.brandCode === BrandsEnum.SKODA ? '695px' : '400px',
    },
    minHeight: theme.brandCode === BrandsEnum.SKODA ? '52px' : '60px',
    textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'auto',
  },
  introduction: {
    fontSize: '1rem',
  },
  agreementInstruction: {
    fontSize: '0.8rem',
    margin: theme.spacing(),

    '& a': {
      color:
        theme.brandCode === BrandsEnum.SKODA
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '0vw',
      maxWidth: '100vw',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      minWidth: '0vw',
      maxWidth: '100vw',
    },
    [theme.breakpoints.up('md')]: {
      Width: '800px',
    },
    maxHeight: '500px',
    padding: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
  },
  errorWrapper: {
    height: '30px',
  },
  label: {
    fontSize: '0.875rem',
  },
}));

const Intro: React.FunctionComponent<IntroProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    agreement,
    introduction,
    optOut,
    startButtonText = 'Start the questionnaire',
  } = props;

  const agreementRequired = Boolean(agreement && agreement.text);

  const [agreementChecked, setAgreementChecked] = React.useState<boolean>(
    false
  );
  const [openOptOutModal, setopenOptOutModal] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);

  const onCancelClickHandler = () => {
    setopenOptOutModal(false);
  };

  const onOptOutLinkClick = () => {
    setopenOptOutModal(true);
  };

  const agreementCheckHandler = (e) => {
    setAgreementChecked(e.target.checked);
    setShowError(!e.target.checked);
  };

  const validate = () => {
    setShowError(!agreementChecked);
  };

  const startClickHandler = () => {
    if (agreementRequired && !agreementChecked) {
      validate();
      return;
    }
    props.onStart();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        wrap="nowrap">
        <Grid item className={classes.fullWidthGridItem}>
          <Typography
            variant="body2"
            className={classes.introduction}
            gutterBottom
            align="left"
            dangerouslySetInnerHTML={{ __html: nl2br(introduction) }}
          />
        </Grid>
        <Grid item>
          <img
            className={classes.image}
            // TODO: hot fix až backend nebude posílat obrázky, pokud nejsou nastavené, vrátit zpět
            // @ts-ignore
            src={theme.images.introDefaultImagePath}
          />
        </Grid>
        {theme.brandCode === BrandsEnum.SKODA ? (
          <Grid item>
            <Button
              data-test-id="startBtn"
              onClick={startClickHandler}
              color={
                agreementRequired && !agreementChecked ? 'primary' : 'primary'
              }
              className={classes.squareButton}
              variant="contained">
              <Typography variant="h5" color="inherit">
                {startButtonText}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ''
        )}
        <Grid item className={classes.fullWidthGridItem}>
          {agreementRequired ? (
            <div>
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                data-test-id="checkBox"
                control={
                  <Checkbox
                    onClick={agreementCheckHandler}
                    value="agreement"
                    checked={agreementChecked}
                  />
                }
                label={agreement!.text}
              />
              <div className={classes.errorWrapper}>
                {showError && (
                  <Typography
                    style={{ fontSize: '0.875rem' }}
                    align="center"
                    color="error">
                    {agreement!.errorMessage}
                  </Typography>
                )}
              </div>
              {agreement!.instruction ? (
                <Typography
                  variant="body1"
                  className={classes.agreementInstruction}>
                  <Linkify properties={{ target: '_blank' }}>
                    {striptags(agreement!.instruction)}
                  </Linkify>
                </Typography>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </Grid>
        {theme.brandCode === BrandsEnum.SKODA ? (
          ''
        ) : (
          <Grid item>
            <Button
              data-test-id="startBtn"
              onClick={startClickHandler}
              size="large"
              color={
                agreementRequired && !agreementChecked ? 'default' : 'primary'
              }
              className={classes.squareButton}
              variant="contained">
              <Typography variant="h4" color="inherit">
                {startButtonText}
              </Typography>
            </Button>
          </Grid>
        )}

        <Grid item>
          {optOut && (
            <OptOut
              optOut={optOut}
              onCancelClickHandler={onCancelClickHandler}
              onOptOutLinkClick={onOptOutLinkClick}
              openOptOutModal={openOptOutModal}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Intro;
